body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.social-card {
  box-shadow: #8080801c -1px 1px 9px 4px;
  background: #fff;
  border-radius: 9px;
}

.ql-toolbar.ql-snow {
  border: none !important;
}
.ql-container.ql-snow {
  border: none !important;
}
